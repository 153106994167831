import { isPrivateEnv } from '@/config';
import queryString from 'query-string';

// 将下划线或者中划线命名转换为驼峰命名
export const toCamelCase = (str) => {
  return str
    .replace(/[_-]([a-zA-Z])/g, (match, p1) => p1.toUpperCase())
    .replace(/^[A-Z]/, (match) => match.toLowerCase());
};

// 将下划线命名转换为驼峰命名， 首字母大写
export const toPascalCase = (str) => {
  return str.replace(/(^|[_-])([a-z])/g, (match, p1, p2) => p2.toUpperCase());
};

export const toDashline = (str: string) => {
  return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
};

// 静默替换 url 的 search 信息
export const replaceChangeUrl = (key, value) => {
  // 获取当前的 URL 和 search 部分
  const url = window.location.href;
  const parsedUrl = new URL(url);

  // 解析查询字符串
  const parsedQuery = queryString.parse(parsedUrl.search);

  // 更新 search 信息
  parsedQuery[key] = value;

  // 构建新的查询字符串
  const newSearch = queryString.stringify(parsedQuery);

  // 返回更新后的 URL
  const str = `${parsedUrl.origin}${parsedUrl.pathname}?${newSearch}${parsedUrl.hash}`;

  // 静默替换 url
  window.history.replaceState(null, '', str);
};
// 去除空格
export const trim = (str) => {
  const reg = /[\t\r\f\n\s]*/g;
  if (typeof str === 'string') return str.replace(reg, '');
  return str;
};

export const openInApp = (url: string) => {
  const protocol =
    process.env.NODE_ENV !== 'production'
      ? 'tocotestdev'
      : isPrivateEnv()
        ? 'tocotest'
        : 'tocoapp';
  window.open(`${protocol}://${url}`);
};
