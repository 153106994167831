import { UserInfoType } from '@/app/common';
import services from '@/services';
import { Image, Popover, Tooltip } from '@df/toco-ui';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import Icon from '../icon';
import styles from './index.module.css';

type UserPropsType = {
  data?: UserInfoType | null;
  readonly?: boolean;
};

function getColorForLetter(letter) {
  if (!letter || typeof letter !== 'string') return '#ffffff';
  const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const index = letters.indexOf(letter.toUpperCase());

  // 确保 letter 是有效的 A-Z 字母
  if (index === -1) {
    return '#1677ff';
  }

  // 计算色相值，每个字母对应一个不同的色相
  const hue = (index / 26) * 360; // 360度色相均分给26个字母
  const saturation = 60; // 饱和度设置为60%
  const lightness = 70; // 亮度设置为70%

  // 生成 HSL 颜色
  const color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  return color;
}

export const renderUserIcon = (data) => {
  if (!data) return null;
  const { avatar, nickname } = data;
  if (avatar) {
    return (
      <Image
        src={avatar}
        style={{
          width: 32,
          height: 32,
          borderRadius: '50%',
        }}
        preview={false}
      />
    );
  } else {
    const letter = nickname?.slice(0, 1) ?? '';
    return (
      <div
        className={styles?.['user-nickname']}
        style={{
          backgroundColor: getColorForLetter(letter),
        }}
      >
        <span>{letter?.toLocaleUpperCase()}</span>
      </div>
    );
  }
};

const Index = (props: UserPropsType) => {
  const { data, readonly } = props;
  const navigate = useNavigate();
  const handleLogout = async () => {
    // FIXME 登出
    const res = await services.UserAccountController_logout_7c3e9b();
    navigate('/login');
  };

  const handleToDetal = () => {
    navigate(`/member/${data?.id}/home`);
  };

  const renderContent = () => {
    return (
      <div className={styles?.content}>
        <div className={styles?.first} onClick={handleToDetal}>
          <span className={styles?.logo}>{renderUserIcon(data)}</span>
          <span className={classNames([styles?.nickname, 'ellipsis1'])}>
            {data?.nickname}的主页
          </span>
        </div>
        <div className={styles?.second} onClick={handleLogout}>
          <Icon type="toco-m-zhuxiao" size={20} />
          <span className={styles?.word}>退出登录</span>
        </div>
      </div>
    );
  };

  if (readonly) {
    return (
      <div className={styles?.['user-container']}>{renderUserIcon(data)}</div>
    );
  }

  return (
    <Popover
      trigger={['hover']}
      title=""
      content={renderContent}
      placement="bottomRight"
      arrow={false}
      overlayClassName={styles?.['user-info-popover']}
    >
      <div className={styles?.['user-container']}>{renderUserIcon(data)}</div>
    </Popover>
  );
};

type UserBasicPropsType = {
  data?: UserInfoType;
  text?: string;
  self?: boolean;
};

export const UserBasic = (props: UserBasicPropsType) => {
  const { data, text, self } = props;
  return (
    <div className={styles?.['user-basic']}>
      <Index data={data} readonly />
      {text ? (
        <Tooltip title={text ?? '-'}>
          <div className={classNames(['ellipsis1'])}>{text ?? '-'}</div>
        </Tooltip>
      ) : (
        <span>{text}</span>
      )}
      {self && <div className={styles?.['user-self']}>我</div>}
    </div>
  );
};

export default Index;
