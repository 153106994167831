import '@/assets/icon/iconfont';

import { BASENAME } from '@/config';
import Layout from '@/layout';
import { lazy } from 'react';
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
} from 'react-router-dom';

const Account = lazy(() => import('@/modules/account'));
const Company = lazy(() => import('@/modules/company'));
const Organization = lazy(() => import('@/modules/organization'));
const OrganizationDetail = lazy(() => import('@/modules/organization/detail'));
const OrganizationRedirect = lazy(
  () => import('@/modules/organization/redirect'),
);
// const Project = lazy(() => import('@/modules/project'));
const Login = lazy(() => import('@/modules/login'));
const Invite = lazy(() => import('@/modules/invite'));
const Member = lazy(() => import('@/modules/member'));
const RegionManage = lazy(() => import('@/modules/region/index'));
const RegionSetting = lazy(() => import('@/modules/region/setting'));
const WorkbenchProject = lazy(() => import('@/modules/workbench/project'));
const WorkbenchOrganization = lazy(
  () => import('@/modules/workbench/organization'),
);
const OrganizationHome = lazy(() => import('@/modules/organization/home'));
const ProjectHome = lazy(() => import('@/modules/project/home'));
const MemberHome = lazy(() => import('@/modules/member/home'));
const Forbidden = lazy(() => import('@/modules/forbidden'));
const UserAction = lazy(() => import('@/modules/user_action'));
const FeComponentDetail = lazy(
  () => import('@/modules/organization/fe-components/detail'),
);

const router = createBrowserRouter(
  [
    {
      path: '/forbidden',
      element: <Forbidden />,
    },
    {
      path: '/',
      element: <Layout />,
      children: [
        {
          index: true, // 默认子路由
          element: <Navigate to="/organization" replace />, // 重定向到 /organization
        },
        {
          path: '/organization/:id/home',
          element: <OrganizationHome />,
        },
        {
          path: '/organization/:id/fecomp/:cid',
          element: <FeComponentDetail />,
        },
        {
          path: '/project/:id/home',
          element: <ProjectHome />,
        },
        {
          path: '/member/:id/home',
          element: <MemberHome />,
        },
        {
          path: '/invite',
          element: <Invite />,
        },
        {
          path: '/account',
          element: <Account />,
        },
        {
          path: '/company',
          element: <Company />,
        },
        {
          path: '/organization',
          element: <OrganizationRedirect />,
        },
        // {
        //   path: '/member',
        //   element: <Member />,
        // },
        // {
        //   path: '/organization/:id',
        //   element: <OrganizationDetail />,
        // },
        // {
        //   path: '/project',
        //   element: <Project />,
        // },
        // {
        //   path: '/project/:id',
        //   element: <ProjectHome />,
        // },
        {
          path: '/region',
          element: <RegionManage />,
        },
        {
          path: '/workbench',
          element: <Outlet />,
          children: [
            {
              path: 'project',
              element: <WorkbenchProject />,
            },
            {
              path: 'organization',
              element: <WorkbenchOrganization />,
            },
          ],
        },
        {
          path: '/user_action',
          element: <UserAction />,
        },
        // {
        //   path: '*',
        //   element: <Navigate to="/project" replace />,
        // },
      ],
    },
    {
      path: '/login',
      element: <Login />,
    },
  ],
  {
    basename: BASENAME,
  },
);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
