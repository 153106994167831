export const CSS_VARIABLE_PREFIX = 'toco';

export const dbOptions = [
  { value: 'mysql', label: 'MySQL' },
  { value: 'postgresql', label: 'PostgreSQL' },
  { value: 'oracle', label: 'Oracle' },
];

export const projectStatusList = [
  {
    label: '准备中',
    value: 'PREPARING',
  },
  {
    label: '开发中',
    value: 'DEV',
  },
  {
    label: '维护中',
    value: 'MAINTAIN',
  },
  {
    label: '已暂停',
    value: 'SUSPEND',
  },
  {
    label: '已完成',
    value: 'FINISHED',
  },
  {
    label: '已注销',
    value: 'CANCELED',
  },
];

export const projectStatusMap = projectStatusList.reduce((acc, item) => {
  acc[item.value] = item.label;
  return acc;
}, {});

export const projectSelfStatusList = [
  {
    label: '我负责的',
    value: 'SUPER_ADMIN',
  },
  {
    label: '我参与的',
    value: 'PARTICIPATE',
  },
  {
    label: '未参与的',
    value: 'UNINVOLVED',
  },
];

export const projectVisibilityList = [
  {
    label: '所有人',
    value: 'ALL',
  },
  {
    label: '正式员工',
    value: 'DEV',
  },
  {
    label: '仅参与者',
    value: 'PARTICIPATE_ONLY',
  },
];

export const projectVisibilityMap = projectVisibilityList.reduce(
  (acc, item) => {
    acc[item.value] = item.label;
    return acc;
  },
  {},
);

export const visibilityRoleList = [
  {
    label: '管理员',
    value: 'ADMIN',
  },
  {
    label: '组织成员',
    value: 'PARTICIPATE',
  },
  // {
  //   label: '非正式成员',
  //   value: 'TEMPORARY',
  // },
];

export const visibilityRoleMap = visibilityRoleList.reduce((acc, item) => {
  acc[item.value] = item.label;
  return acc;
}, {});

export const visibilityRoleProjectList = [
  {
    label: '项目负责人',
    value: 'SUPER_ADMIN',
  },
  {
    label: '项目成员',
    value: 'PARTICIPATE',
  },
];

export const visibilityRoleProjectMap = visibilityRoleProjectList.reduce(
  (acc, item) => {
    acc[item.value] = item.label;
    return acc;
  },
  {},
);

export const roleList = [
  {
    label: '超级管理员',
    value: 'SUPER_ADMIN',
  },
  {
    label: '管理员',
    value: 'ADMIN',
  },
  {
    label: '组织成员',
    value: 'PARTICIPATE',
  },
  // {
  //   label: '非正式成员',
  //   value: 'TEMPORARY',
  // },
];

export const roleMap = roleList.reduce((acc, item) => {
  acc[item.value] = item.label;
  return acc;
}, {});

export const userStatusList = [
  {
    label: '正常',
    value: 'NORMAL',
  },
  {
    label: '禁用',
    value: 'BANNED',
  },
];

export const userStatusMap = userStatusList.reduce((acc, item) => {
  acc[item.value] = item.label;
  return acc;
}, {});

export const userActionWindow = [
  {
    label: '日期',
    value: 'DAY',
  },
  {
    label: '周',
    value: 'WEEK',
  },
  {
    label: '月份',
    value: 'MONTH',
  },
  {
    label: '季节',
    value: 'SEASON',
  },
];

export const userActionWindowMap = userStatusList.reduce((acc, item) => {
  acc[item.value] = item.label;
  return acc;
}, {});

export const BASENAME = '/manage';

const privateDomain = '.teitui.com';
export const isPrivateEnv = () => {
  return window.location.host?.indexOf(privateDomain) > 0;
};
